import {selectLoadingState, selectMaybeLoaded} from "app/state/common/loadable";
import {IStreamingLinks} from "app/gql/graphqlSchema";
import {useBootstrapStore} from "app/state/bootstrap/mutations";

export const useBootstrapBackofficeLoadingState = () => {
  return useBootstrapStore((state) => selectLoadingState(state.bootstrapResult));
};

export const useBootstrapBackofficeResult = () => {
  return useBootstrapStore((state) => selectMaybeLoaded(state.bootstrapResult));
};

export const useBackofficeLoggedState = () => {
  return useBootstrapStore((state) => selectMaybeLoaded(state.bootstrapResult));
};

export const useVideoStreams = (streamingLinks: IStreamingLinks) => {
  const boostrapResult = useBootstrapBackofficeResult();
  if (boostrapResult?.__typename === "BootstrapBoUser") {
    return {
      mpd: boostrapResult.vodUrlPrefix + streamingLinks.dash,
      hls: boostrapResult.vodUrlPrefix + streamingLinks.hls,
      poster: boostrapResult.vodUrlPrefix + streamingLinks.thumbnail
    };
  }
  return;
};
