import {useHistory} from "react-router-dom";
import {useCallback} from "react";

export const useNavigate = () => {
  const history = useHistory();
  const navigate: typeof history.push = useCallback(
    (location, state) => {
      history.push(location, state);
    },
    [history]
  );
  return navigate;
};
