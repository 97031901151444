import {useCallback} from "react";
import {
  DefaultButton,
  Dialog,
  DialogFooter,
  IDialogContentProps,
  IModalProps,
  MessageBar,
  MessageBarType
} from "@fluentui/react";
import {FallbackRender} from "@sentry/react/types/errorboundary";
import {useBoolean} from "@fluentui/react-hooks";
import {useNavigate} from "app/components/hooks/useNavigate";

const modalProps: IModalProps = {
  isDarkOverlay: false
};

const fallbackRender: FallbackRender = (errorData) => {
  return <UnexpectedError error={errorData.error} resetError={errorData.resetError} />;
};

const dialogProps: IDialogContentProps = {
  title: "Вътрешна грешка на приложението",
  showCloseButton: true
};

interface IProps {
  error: Error;
  resetError: () => void;
}

const UnexpectedError = ({error, resetError}: IProps) => {
  const [showDialog, {setFalse: hideDialog}] = useBoolean(true);
  const navigate = useNavigate();
  const onDismiss = useCallback(() => {
    hideDialog();
    resetError();
    navigate("/");
  }, [hideDialog, navigate, resetError]);

  return (
    <Dialog
      dialogContentProps={dialogProps}
      hidden={!showDialog}
      maxWidth="auto"
      modalProps={modalProps}
      onDismiss={onDismiss}
    >
      <MessageBar messageBarType={MessageBarType.error}>
        <strong>{error.name}</strong>: {error.message}
      </MessageBar>

      <DialogFooter>
        <DefaultButton onClick={onDismiss} text="Презареди" />
      </DialogFooter>
    </Dialog>
  );
};

export default fallbackRender;
