import {
  AcceptIcon,
  AccountActivityIcon,
  AccountManagementIcon,
  ActivateOrdersIcon,
  AddIcon,
  AlignCenterIcon,
  AlignLeftIcon,
  AlignRightIcon,
  AlignVerticalBottomIcon,
  AlignVerticalTopIcon,
  ArchiveIcon,
  AttachIcon,
  BackIcon,
  BlockedSiteIcon,
  BoldIcon,
  BoxCheckmarkSolidIcon,
  BoxMultiplySolidIcon,
  BulletedListIcon,
  CalculatorMultiplyIcon,
  CalendarIcon,
  CancelIcon,
  ChartIcon,
  CheckMarkIcon,
  ChevronDownIcon,
  ChevronDownSmallIcon,
  ChevronRightIcon,
  ChevronRightMedIcon,
  ChevronUpSmallIcon,
  CircleRingIcon,
  ClearFormattingIcon,
  ClearIcon,
  CloudWeatherIcon,
  CommentIcon,
  ContextMenuIcon,
  CopyIcon,
  createSvgIcon,
  DecreaseIndentIcon,
  DeleteIcon,
  DietPlanNotebookIcon,
  DoubleChevronLeftIcon,
  DoubleChevronRightIcon,
  DownIcon,
  DownloadDocumentIcon,
  DownloadIcon,
  EditIcon,
  ErrorBadgeIcon,
  FeedbackIcon,
  FontDecreaseIcon,
  FontIncreaseIcon,
  FullScreenIcon,
  GlobalNavButtonActiveIcon,
  GlobalNavButtonIcon,
  GoIcon,
  HelpIcon,
  HideIcon,
  HighlightIcon,
  HomeIcon,
  HomeVerifyIcon,
  HourGlassIcon,
  ImportantIcon,
  IncreaseIndentIcon,
  InfoIcon,
  ItalicIcon,
  LinkIcon,
  LockIcon,
  LockSolidIcon,
  MailIcon,
  MoreIcon,
  NavigateBackMirroredIcon,
  NewFolderIcon,
  NumberedListIcon,
  PDFIcon,
  PeopleIcon,
  PlaybackRate1xIcon,
  PlayIcon,
  PowerButtonIcon,
  PrintIcon,
  RedEyeIcon,
  RefreshIcon,
  ReminderPersonIcon,
  RemoveContentIcon,
  RemoveIcon,
  ReportDocumentIcon,
  RerunIcon,
  ReturnKeyIcon,
  RingerIcon,
  RocketIcon,
  RotateIcon,
  SaveAndCloseIcon,
  SaveIcon,
  SearchIcon,
  SendIcon,
  ShareIcon,
  SkypeCheckIcon,
  SortDownIcon,
  SortUpIcon,
  SourceIcon,
  StatusCircleBlockIcon,
  StatusCircleCheckmarkIcon,
  StrikethroughIcon,
  TableIcon,
  TagIcon,
  TelemarketerIcon,
  TextDocumentIcon,
  TripleColumnEditIcon,
  UnderlineIcon,
  UnlockIcon,
  UpIcon,
  ViewIcon,
  WindowEditIcon
} from "@fluentui/react-icons-mdl2";
import {IIconProps} from "@fluentui/react";
import {GoogleIcon} from "app/utils/icons/google";
import {LoginIcon} from "app/utils/icons/login";
import {LogoutIcon} from "app/utils/icons/logout";

import ShopSvg from "bootstrap-icons/icons/shop.svg?react";
import PlayBtnSvg from "bootstrap-icons/icons/play-btn.svg?react";
import GearFullSvg from "bootstrap-icons/icons/gear-fill.svg?react";
import FileEarmarkCheckSvg from "bootstrap-icons/icons/file-earmark-check.svg?react";
import PersonWorkspaceSvg from "bootstrap-icons/icons/person-workspace.svg?react";
import ManagerSvg from "app/utils/icons/manager.svg?react";
import HourGlassSplitSvg from "bootstrap-icons/icons/hourglass-split.svg?react";
import CheckCircleFillSvg from "bootstrap-icons/icons/check-circle-fill.svg?react";
import ExclamationDiamondFillSvg from "bootstrap-icons/icons/exclamation-diamond-fill.svg?react";

const ShopIcon = createSvgIcon({
  svg: ({classes: {svg}}) => <ShopSvg className={svg} />,
  displayName: "ShopSvg"
});

const PlayBtnIcon = createSvgIcon({
  svg: ({classes: {svg}}) => <PlayBtnSvg className={svg} />,
  displayName: "PlayBtnSvg"
});

const GearFullIcon = createSvgIcon({
  svg: ({classes: {svg}}) => <GearFullSvg className={svg} />,
  displayName: "GearFullSvg"
});

const ManagerIcon = createSvgIcon({
  svg: ({classes: {svg}}) => <ManagerSvg className={svg} />,
  displayName: "ManagerSvg"
});

const FileEarmarkCheckIcon = createSvgIcon({
  svg: ({classes: {svg}}) => <FileEarmarkCheckSvg className={svg} />,
  displayName: "FileEarmarkCheckSvg"
});

const PersonWorkspaceIcon = createSvgIcon({
  svg: ({classes: {svg}}) => <PersonWorkspaceSvg className={svg} />,
  displayName: "PersonWorkspaceSvg"
});

const HourGlassSplitIcon = createSvgIcon({
  svg: ({classes: {svg}}) => <HourGlassSplitSvg className={svg} />,
  displayName: "HourGlassSplitSvg"
});

const CheckCircleFillIcon = createSvgIcon({
  svg: ({classes: {svg}}) => <CheckCircleFillSvg className={svg} />,
  displayName: "CheckCircleFillSvg"
});

const ExclamationDiamondFillIcon = createSvgIcon({
  svg: ({classes: {svg}}) => <ExclamationDiamondFillSvg className={svg} />,
  displayName: "ExclamationDiamondFillSvg"
});

export const ICONS = {
  cancel: <CancelIcon />,
  NavigateBackMirrored: <NavigateBackMirroredIcon />,
  errorbadge: <ErrorBadgeIcon />,
  chevronupsmall: <ChevronUpSmallIcon />,
  chevrondownsmall: <ChevronDownSmallIcon />,
  chevrondown: <ChevronDownIcon />,
  chevronright: <ChevronRightIcon />,
  more: <MoreIcon />,
  DoubleChevronLeft: <DoubleChevronLeftIcon />,
  DoubleChevronRight: <DoubleChevronRightIcon />,
  clear: <ClearIcon />,
  search: <SearchIcon />,
  sortUp: <SortUpIcon />,
  sortDown: <SortDownIcon />,
  up: <UpIcon />,
  down: <DownIcon />,
  calendar: <CalendarIcon />,
  edit: <EditIcon />,
  add: <AddIcon />,
  Remove: <RemoveIcon />,
  people: <PeopleIcon />,
  reminderPerson: <ReminderPersonIcon />,
  rocket: <RocketIcon />,
  redeye: <RedEyeIcon />,
  hide: <HideIcon />,
  back: <BackIcon />,
  PowerButton: <PowerButtonIcon />,
  AccountManagement: <AccountManagementIcon />,
  home: <HomeIcon />,
  telemarketer: <TelemarketerIcon />,
  checkmark: <CheckMarkIcon />,
  circlering: <CircleRingIcon />,
  statuscirclecheckmark: <StatusCircleCheckmarkIcon />,
  DietPlanNotebookIcon: <DietPlanNotebookIcon />,
  feedback: <FeedbackIcon />,
  Important: <ImportantIcon />,
  LockSolid: <LockSolidIcon />,
  Play: <PlayIcon />,
  Rerun: <RerunIcon />,
  PlaybackRate1x: <PlaybackRate1xIcon />,
  GlobalNavButton: <GlobalNavButtonIcon />,
  GlobalNavButtonActive: <GlobalNavButtonActiveIcon />,
  BoxCheckmarkSolid: <BoxCheckmarkSolidIcon />,
  FullScreen: <FullScreenIcon />,
  fstGoogle: <GoogleIcon />,
  BoxMultiplySolid: <BoxMultiplySolidIcon />,
  fstLogin: <LoginIcon />,
  fstLogout: <LogoutIcon />,
  store: <ShopIcon />,
  playBtn: <PlayBtnIcon />,
  gearFull: <GearFullIcon />,
  manager: <ManagerIcon />,
  copy: <CopyIcon />,
  protocol: <FileEarmarkCheckIcon />,
  SkypeCheck: <SkypeCheckIcon />,
  StatusCircleBlock: <StatusCircleBlockIcon />,
  info: <InfoIcon />,
  print: <PrintIcon />,
  save: <SaveIcon />,
  saveAndClose: <SaveAndCloseIcon />,
  WindowEdit: <WindowEditIcon />,
  TripleColumnEdit: <TripleColumnEditIcon />,
  Go: <GoIcon />,
  ReportDocument: <ReportDocumentIcon />,
  Position: <PersonWorkspaceIcon />,
  Chart: <ChartIcon />,
  HourGlass: <HourGlassIcon />,
  HourGlassSplit: <HourGlassSplitIcon />,
  CheckCircleFill: <CheckCircleFillIcon />,
  ExclamationDiamondFill: <ExclamationDiamondFillIcon />,
  pdf: <PDFIcon />,
  archive: <ArchiveIcon />,
  refresh: <RefreshIcon />,
  lock: <LockIcon />,
  unlock: <UnlockIcon />,
  Ringer: <RingerIcon />,
  newFolder: <NewFolderIcon />,
  chevronrightmed: <ChevronRightMedIcon />,
  tag: <TagIcon />,
  TextDocument: <TextDocumentIcon />,
  attach: <AttachIcon />,
  calculatormultiply: <CalculatorMultiplyIcon />,
  delete: <DeleteIcon />,
  mail: <MailIcon />,
  share: <ShareIcon />,
  link: <LinkIcon />,
  DownloadDocument: <DownloadDocumentIcon />,
  BlockedSite: <BlockedSiteIcon />,
  Accept: <AcceptIcon />,
  Rotate: <RotateIcon />,
  AccountActivity: <AccountActivityIcon />,
  ActivateOrders: <ActivateOrdersIcon />,
  Strikethrough: <StrikethroughIcon />,
  CloudWeather: <CloudWeatherIcon />,
  HomeVerify: <HomeVerifyIcon />,
  Source: <SourceIcon />,
  Download: <DownloadIcon />,
  RemoveContent: <RemoveContentIcon />,
  Bold: <BoldIcon />,
  Italic: <ItalicIcon />,
  Underline: <UnderlineIcon />,
  Highlight: <HighlightIcon />,
  AlignLeft: <AlignLeftIcon />,
  AlignRight: <AlignRightIcon />,
  AlignCenter: <AlignCenterIcon />,
  ClearFormatting: <ClearFormattingIcon />,
  BulletedList: <BulletedListIcon />,
  NumberedList: <NumberedListIcon />,
  IncreaseIndent: <IncreaseIndentIcon />,
  DecreaseIndent: <DecreaseIndentIcon />,
  FontIncrease: <FontIncreaseIcon />,
  FontDecrease: <FontDecreaseIcon />,
  ReturnKey: <ReturnKeyIcon />,
  AlignVerticalTop: <AlignVerticalTopIcon />,
  AlignVerticalBottom: <AlignVerticalBottomIcon />,
  View: <ViewIcon />,
  ContextMenu: <ContextMenuIcon />,
  Send: <SendIcon />,
  Help: <HelpIcon />,
  Comment: <CommentIcon />,
  Table: <TableIcon />
} as const;

export type IconEnum = keyof typeof ICONS;

type IconNames = {
  [key in IconEnum]: key;
};

export const ICON_NAMES = (Object.keys(ICONS) as IconEnum[]).reduce(
  (acc, k) => {
    acc[k] = k;
    return acc;
  },
  {} as Record<IconEnum, IconEnum>
) as IconNames;

export const ICON_PROPS = (Object.keys(ICONS) as IconEnum[]).reduce(
  (acc, k) => {
    acc[k] = {
      iconName: k
    };
    return acc;
  },
  {} as Record<IconEnum, IIconProps>
);

export default {};
