import {createContext, ReactNode, useCallback, useContext, useEffect, useMemo, useRef, useState} from "react";
import {assertNotNil, isNil} from "app/utils/stdlib";
import {IApplicationError, transformToApplicationError} from "app/gql/handleGraphqlErrors";
import {
  ContextualMenu,
  DefaultButton,
  Dialog,
  DialogFooter,
  DialogType,
  IDialogContentProps,
  IDialogStyles,
  IDragOptions,
  IModalProps,
  MessageBar,
  MessageBarType,
  Modal,
  Spinner,
  SpinnerSize
} from "@fluentui/react";
import {useAsync} from "@fluentui/react-hooks";
import {getErrorMessage} from "app/utils/parseErrorResult";
import {styled} from "styled-components";
import {ICON_PROPS} from "app/utils/icons";
import {useHistory} from "react-router-dom";
import {ApiState, ILoadable} from "app/state/common/loadable";

const useAdminLoading = () => {
  const timeoutIdRef = useRef<number | null>(null);
  const loadingShown = useRef<boolean>(false);
  const async = useAsync();
  const history = useHistory();

  const [loadingState, setLoadingState] = useState(0);

  const startLoading = useCallback(() => setLoadingState((prevState) => prevState + 1), []);
  const finishLoading = useCallback(() => setLoadingState((prevState) => Math.max(prevState - 1, 0)), []);

  const [showLoading, setShowLoading] = useState(false);
  const [appError, setAppError] = useState<IApplicationError | null>(null);

  useEffect(() => {
    const shouldShow = loadingState > 0;

    if (shouldShow) {
      if (loadingShown.current || !isNil(timeoutIdRef.current)) {
        return;
      }
      timeoutIdRef.current = async.setTimeout(() => {
        loadingShown.current = true;
        setShowLoading(true);
      }, 500);
    } else {
      if (!isNil(timeoutIdRef.current)) {
        async.clearTimeout(timeoutIdRef.current);
        timeoutIdRef.current = null;
      }
      if (loadingShown.current) {
        loadingShown.current = false;
        setShowLoading(false);
      }
    }
  }, [async, loadingState]);

  const showAppError = useCallback((appError: IApplicationError) => {
    setShowLoading(false);
    setAppError(appError);
  }, []);

  const showExceptionError = useCallback(
    (e: unknown) => {
      console.error("showExceptionError", e);
      const appError = transformToApplicationError(e);
      showAppError(appError);
    },
    [showAppError]
  );

  const hideAppError = useCallback(() => {
    setAppError(null);
  }, []);

  useEffect(
    () =>
      history.listen(() => {
        setShowLoading(false);
        setAppError(null);
      }),
    [history]
  );

  return useMemo(
    () => ({
      startLoading,
      finishLoading,
      showExceptionError,
      showAppError,
      appError,
      hideAppError,
      showLoading,
      loading: loadingState > 0
    }),
    [startLoading, finishLoading, showExceptionError, showAppError, appError, hideAppError, showLoading, loadingState]
  );
};

const dialogStyles: Partial<IDialogStyles> = {
  main: {
    display: "flex"
  }
};

const Center = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
`;

const dragOptions: IDragOptions = {
  moveMenuItemText: "Move",
  closeMenuItemText: "Close",
  menu: ContextualMenu,
  keepInBounds: true
};

const modalProps: IModalProps = {
  isBlocking: false,
  isDarkOverlay: true,
  dragOptions
};

const DisplayError = ({appError}: {appError: IApplicationError | null}) => {
  if (isNil(appError)) {
    return null;
  }

  const message = getErrorMessage(appError);

  return <MessageBar messageBarType={MessageBarType.error}>{message}</MessageBar>;
};

type IAdminLoadingContext = ReturnType<typeof useAdminLoading>;
const AdminLoadingContext = createContext<IAdminLoadingContext | null>(null);

const errorDialogContentProps: IDialogContentProps = {
  type: DialogType.close,
  title: "Грешка при изпълнение на действието"
};

export const AdminLoadingProvider = ({children}: {children: ReactNode}) => {
  const loading = useAdminLoading();

  const {showLoading, hideAppError} = loading;
  return (
    <AdminLoadingContext.Provider value={loading}>
      <Modal dragOptions={dragOptions} isBlocking isOpen={showLoading} styles={dialogStyles}>
        <Center>
          <Spinner label="Зареждане, моля изчакайте…" size={SpinnerSize.large} />
        </Center>
      </Modal>
      <Dialog
        dialogContentProps={errorDialogContentProps}
        hidden={isNil(loading.appError)}
        modalProps={modalProps}
        onDismiss={hideAppError}
      >
        <DisplayError appError={loading.appError} />
        <DialogFooter>
          <DefaultButton autoFocus iconProps={ICON_PROPS.cancel} onClick={hideAppError} text="Затвори" />
        </DialogFooter>
      </Dialog>
      {children}
    </AdminLoadingContext.Provider>
  );
};

export const useAdminLoadingContext = () => {
  const context = useContext(AdminLoadingContext);
  assertNotNil(context);
  return context;
};

export const useLoadableProgress = <T,>(loadable: ILoadable<T>) => {
  const {loading, startLoading, finishLoading, showExceptionError, showAppError} = useAdminLoadingContext();

  useEffect(() => {
    if (loadable.loadingState === ApiState.LOADING) {
      startLoading();
      return finishLoading;
    } else if (loadable.loadingState === ApiState.ERROR && !isNil(loadable.appError)) {
      showAppError(loadable.appError);
    }
  }, [loadable.loadingState, startLoading, finishLoading, loadable.appError, showAppError]);

  return {loading, showExceptionError, showAppError};
};
