import {Callout, IStyle, Link, mergeStyleSets, Stack, Text} from "@fluentui/react";
import {useRef} from "react";
import Timestamp from "app/components/admin/Timestamp";
import {FeedbackIcon} from "@fluentui/react-icons-mdl2";
import {useBoolean, useOnEvent} from "@fluentui/react-hooks";
import {defaultStackTokens, rowStackTokens} from "app/utils/defaults";
import {useStopPropagation} from "app/components/hooks/useStopPropagation";
import theme from "app/utils/theme";
import {useBootstrapBackofficeResult} from "app/state/bootstrap/selectors";
import {isNil} from "app/utils/stdlib";
import {useAdminStore} from "app/state/admin";
import {AdminMenu} from "app/components/App/AdminMenu";

interface IComponentStyles {
  root: IStyle;
  icon: IStyle;
  content: IStyle;
  copyright: IStyle;
  iconContainer: IStyle;
}

const classNames = mergeStyleSets<IComponentStyles>({
  root: {
    displayName: "Version",
    display: "flex",
    flexDirection: "row",
    padding: "0.1rem 0.5rem",
    alignItems: "center"
  },
  icon: {
    fontSize: "0.8rem",
    cursor: "pointer"
  },
  iconContainer: {
    marginLeft: "auto",
    paddingBottom: 2 // adds scroll on full screen
  },
  content: {
    padding: "0.75rem"
  },
  copyright: {}
});

const cellStyle = {
  root: theme.fonts.small
};

export const Version = () => {
  const iconRef = useRef<HTMLElement | null>(null);
  const [showVersion, {toggle, setFalse: hide}] = useBoolean(false);
  useOnEvent(document, "click", hide);
  const onClickToggle = useStopPropagation(toggle);
  const version = import.meta.env.VITE_BUILD_VERSION;
  const tsStr = import.meta.env.VITE_BUILD_TIMESTAMP;
  const serverBuildInfo = useBootstrapBackofficeResult()?.buildInfo;
  const {testMode} = useAdminStore((state) => state);

  return (
    <div className={`${classNames.root} no-print`}>
      <div>
        <Text className={classNames.copyright} variant="xSmall">
          © 2025 Еф Ес Академи ООД ⋅{" "}
          <Link href="/legal/privacy-policy/latest" target="_blank">
            Политика за лични данни
          </Link>
        </Text>
      </div>
      {/*<TooltipHost calloutProps={tooltipCalloutProps} content="Служебен вход">*/}
      {/*  <FstLinkIconButton iconProps={ICON_PROPS.gearFull} to={linkAdmin()} />*/}
      {/*</TooltipHost>*/}
      <span className={classNames.iconContainer} ref={iconRef}>
        <FeedbackIcon className={classNames.icon} onClick={onClickToggle} />
      </span>

      <Callout beakWidth={8} hidden={!showVersion} target={iconRef}>
        <Stack className={classNames.content} tokens={defaultStackTokens}>
          <Stack horizontal tokens={rowStackTokens} verticalAlign="center">
            <Stack.Item styles={cellStyle}>
              <strong>Клиент</strong>:
            </Stack.Item>
            <Stack.Item styles={cellStyle}>{version}</Stack.Item>

            {isNil(tsStr) ? null : (
              <Stack.Item styles={cellStyle}>
                <Timestamp ts={tsStr} />
              </Stack.Item>
            )}
            {testMode && (
              <Stack.Item styles={cellStyle}>
                <AdminMenu />
              </Stack.Item>
            )}
          </Stack>
          {isNil(serverBuildInfo) ? null : (
            <Stack horizontal tokens={rowStackTokens}>
              <Stack.Item styles={cellStyle}>
                <strong>Сървър</strong>:
              </Stack.Item>
              <Stack.Item styles={cellStyle}>{serverBuildInfo.version}</Stack.Item>
              <Stack.Item styles={cellStyle}>
                <Timestamp ts={serverBuildInfo.buildTime} />
              </Stack.Item>
            </Stack>
          )}
        </Stack>
      </Callout>
    </div>
  );
};
