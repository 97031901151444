import {MouseEventHandler, useCallback} from "react";

export const useStopPropagation = (func: () => unknown): MouseEventHandler =>
  useCallback(
    (e) => {
      func();
      e.stopPropagation();
    },
    [func]
  );
