import {IPredicatePageInput, Sort} from "app/gql/graphqlSchema";
import {ContextualMenu, IModalProps, IStackTokens, mergeStyles} from "@fluentui/react";

export const defaultPredicatePage: IPredicatePageInput = {
  number: 1,
  size: 10,
  order: [
    {
      name: "name",
      sort: Sort.Asc
    }
  ]
};

export const defaultStackTokens: IStackTokens = {
  childrenGap: "1em"
};

export const rowStackTokens: IStackTokens = {
  childrenGap: "0.5rem"
};

export const space = mergeStyles({
  displayName: "space",
  marginLeft: "0.5em"
});

export const defaultModalProps: IModalProps = {
  isBlocking: true,
  dragOptions: {
    moveMenuItemText: "Move",
    closeMenuItemText: "Close",
    menu: ContextualMenu,
    keepInBounds: true
  },
  isClickableOutsideFocusTrap: true // to make uploady work
};
