import {lazy, Suspense} from "react";
import {IStyle, mergeStyleSets, Stack} from "@fluentui/react";
import {Switch} from "react-router-dom";
import {AdminNotFoundPage} from "app/components/AdminNotFoundPage";
import {Loading} from "app/components/Loading";
import {ErrorBoundary} from "@sentry/react";
import fallbackRender from "app/components/FallbackRender";
import {ToastContainer} from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {Version} from "app/components/App/Version";
import {SentryRoute} from "app/sentryRoutes";
import {Autoupdate} from "app/components/Autoupdate";
import {AdminLoadingProvider} from "app/components/AdminLoadingContext";

const AdminLazy = lazy(() => import("app/components/Admin"));
const PrintScreenLazy = lazy(() => import("app/components/print/PrintScreen"));
const ManagersLazy = lazy(() => import("app/components/Manager"));
const LandingLazy = lazy(() => import("app/landing/Landing"));

interface IComponentStyles {
  container: IStyle;
  content: IStyle;
}

const classNames = mergeStyleSets<IComponentStyles>({
  container: {
    displayName: "AppContainer",
    display: "flex",
    flexDirection: "column",
    minHeight: "var(--inner-height)"
  },
  content: {
    flexGrow: 1
  }
});

export const App = () => {
  return (
    <ErrorBoundary fallback={fallbackRender}>
      <Autoupdate />
      <AdminLoadingProvider>
        <div className={classNames.container}>
          <Stack className={classNames.content}>
            <Stack grow>
              <Switch>
                <SentryRoute exact path="/print">
                  <Suspense fallback={<Loading />}>
                    <PrintScreenLazy />
                  </Suspense>
                </SentryRoute>
                <SentryRoute path="/admin">
                  <Suspense fallback={<Loading />}>
                    <AdminLazy />
                  </Suspense>
                </SentryRoute>
                <SentryRoute path="/managers">
                  <Suspense fallback={<Loading />}>
                    <ManagersLazy />
                  </Suspense>
                </SentryRoute>
                <SentryRoute exact path="/">
                  <Suspense fallback={<Loading />}>
                    <LandingLazy />
                  </Suspense>
                </SentryRoute>
                <SentryRoute path="*">
                  <AdminNotFoundPage />
                </SentryRoute>
              </Switch>
            </Stack>
          </Stack>
          <Version />
        </div>
      </AdminLoadingProvider>
      <ToastContainer hideProgressBar />
    </ErrorBoundary>
  );
};
