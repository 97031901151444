import {
  AutoupdateCreateRevisionDocument,
  BootstrapBackofficeDocument,
  IAutoupdate,
  IBootstrapBackofficeQuery,
  ILoginBackofficeMutationVariables,
  LoginBackofficeDocument,
  LogoutBackofficeDocument
} from "app/gql/graphqlSchema";
import {ApiState, ILoadable, loadedOk, LOADING_STATE_ERROR, LOADING_STATE_NA} from "app/state/common/loadable";
import {query} from "app/gql/client";
import {assertNotNil} from "app/utils/stdlib";
import {setUser} from "@sentry/react";
import {MaybeNil} from "app/utils/types";
import {createWithEqualityFn as zustandCreate} from "zustand/traditional";
import {immer} from "zustand/middleware/immer";
import {overrideTestMode} from "app/state/admin";
import {loadLanguages} from "app/state/language/mutations";

type IBootstrapResult = IBootstrapBackofficeQuery["bootstrap"];

export interface IBoostrapState {
  bootstrapResult: ILoadable<IBootstrapResult>;
  logout: ILoadable<boolean>;
  autoupdateRevision: MaybeNil<IAutoupdate>;
}

const initialState: IBoostrapState = {
  bootstrapResult: LOADING_STATE_NA,
  logout: LOADING_STATE_NA,
  autoupdateRevision: null
};

export const useBootstrapStore = zustandCreate(
  immer(() => initialState),
  Object.is
);

export const bootstrap = async () => {
  try {
    useBootstrapStore.setState((state) => {
      state.bootstrapResult.loadingState = ApiState.LOADING;
    });
    const clientResult = await query(BootstrapBackofficeDocument);
    if (clientResult.bootstrap.__typename === "BootstrapBoUser") {
      setUser({id: clientResult.bootstrap.boUser.id, segment: "backoffice"});
    } else {
      setUser(null);
    }
    useBootstrapStore.setState((state) => {
      state.bootstrapResult = loadedOk(clientResult.bootstrap);
      state.autoupdateRevision = clientResult.autoupdateRevision;
    });
    overrideTestMode(clientResult.bootstrap.testMode);
    loadLanguages(clientResult.allLanguages);
  } catch (e) {
    useBootstrapStore.setState((state) => {
      state.bootstrapResult = LOADING_STATE_ERROR;
    });
    throw e;
  }
};

export const createAutoupdateRevision = async () => {
  const clientResult = await query(AutoupdateCreateRevisionDocument);
  useBootstrapStore.setState((state) => {
    state.autoupdateRevision = clientResult?.autoupdateCreateRevision;
  });
};

export const logoutBoUserAndBootstrap = async () => {
  await query(LogoutBackofficeDocument);
  await bootstrap();
};

type ILoginArg = ILoginBackofficeMutationVariables["input"];
export const loginBoUserAndBootstrap = async (input: ILoginArg) => {
  const loginResult = await query(LoginBackofficeDocument, {input});
  assertNotNil(loginResult);
  if (loginResult.loginBackoffice.__typename === "ErrorResult") {
    return loginResult.loginBackoffice;
  } else {
    return await bootstrap();
  }
};

export const reset = () => useBootstrapStore.setState(initialState, true);
