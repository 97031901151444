import {StrictMode} from "react";
import {render} from "react-dom";
import {BrowserRouter} from "react-router-dom";
import {App} from "app/components/App";

import {IStyle, mergeStyles, registerIcons, ThemeProvider} from "@fluentui/react";
import {ICONS} from "app/utils/icons";
import {FONT_FAMILY} from "app/utils/theme";
import "app/app.scss";
import {StyleSheetManager} from "styled-components";
import {forwardAllProps} from "app/utils/stdlib";
import {initI18n} from "app/employee/i18n";
import {I18nextProvider} from "react-i18next";

export default () => {
  // hack for mobile browsers calculating window chrome at 100vh
  const updateInnerHeight = () => {
    const htmlTag = document.documentElement;
    htmlTag.style.setProperty("--inner-height", `${htmlTag.clientHeight}px`);
    htmlTag.style.setProperty("--inner-width", `${htmlTag.clientWidth}px`);
  };
  updateInnerHeight();
  window.addEventListener("resize", updateInnerHeight);

  // Inject some global styles
  mergeStyles({
    ":global(*, *::before, *::after)": {
      boxSizing: "border-box"
    } as IStyle,
    ":global(body, #container)": {
      margin: "0",
      minHeight: "var(--inner-height)"
    } as IStyle,
    ":global(:root)": {
      "--toastify-font-family": `${FONT_FAMILY} !important`
    }
  });

  const themeProvider = mergeStyles({
    displayName: "themeProvider",
    minHeight: "var(--inner-height)"
  });
  registerIcons({
    icons: ICONS
  });

  initI18n().then((i18n) => {
    render(
      <StrictMode>
        <StyleSheetManager enableVendorPrefixes shouldForwardProp={forwardAllProps}>
          <I18nextProvider defaultNS="translation" i18n={i18n}>
            <BrowserRouter>
              <ThemeProvider className={themeProvider}>
                <App />
              </ThemeProvider>
            </BrowserRouter>
          </I18nextProvider>
        </StyleSheetManager>
      </StrictMode>,
      document.getElementById("container")
    );
  });
};
