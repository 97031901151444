import {Text} from "@fluentui/react";
import {styled} from "styled-components";
import LogoSvg from "app/utils/icons/logo.svg?react";

const Logo = styled(LogoSvg)`
  max-height: 250px;
`;

const Wrapper = styled.div`
  margin: 3em;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 1rem;
`;

export const AdminNotFoundPage = () => {
  return (
    <Wrapper>
      <Logo />
      <Text variant="xLarge">Тази страница не съществува или нямате достъп до нея.</Text>
    </Wrapper>
  );
};
