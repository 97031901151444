import {createSvgIcon} from "@fluentui/react-icons-mdl2";

export const GoogleIcon = createSvgIcon({
  svg: ({classes}) => (
    <svg className={classes.className} focusable={false} viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
      <path d="M12.545 10.239v3.821h5.445c-.712 2.315-2.647 3.972-5.445 3.972a6.033 6.033 0 1 1 0-12.064c1.498 0 2.866.549 3.921 1.453l2.814-2.814A9.969 9.969 0 0 0 12.545 2C7.021 2 2.543 6.477 2.543 12s4.478 10 10.002 10c8.396 0 10.249-7.85 9.426-11.748l-9.426-.013z" />
    </svg>
  ),
  displayName: "fstGoogle"
});
