import {ReactNode, useEffect} from "react";
import {IStyle, mergeStyleSets, Spinner, SpinnerSize} from "@fluentui/react";
import {useBoolean} from "@fluentui/react-hooks";
import theme from "app/utils/theme";
import {ApiState, ILoadable} from "app/state/common/loadable";
import {UnreachableCaseError} from "ts-essentials";

interface IComponentStyles {
  root: IStyle;
  spinner: IStyle;
}

const classNames = mergeStyleSets<IComponentStyles>({
  root: {
    displayName: "Loading",
    display: "flex",
    position: "absolute",
    top: "0",
    left: "0",
    right: "0",
    bottom: "0",
    alignItems: "center",
    justifyContent: "center",
    backdropFilter: "blur(2px)",
    backgroundColor: "rgba(218,218,218,0.05)",
    zIndex: 10
  },
  spinner: {
    padding: "1rem",
    borderWidth: "1px",
    borderStyle: "solid",
    borderColor: theme.semanticColors.link,
    borderRadius: "0.5rem",
    backgroundColor: theme.semanticColors.bodyBackground
  }
});

export const Loading = () => {
  const [show, {setTrue: setShow}] = useBoolean(false);

  useEffect(() => {
    const timeoutId = setTimeout(() => setShow(), 500);
    return () => {
      clearTimeout(timeoutId);
    };
  }, [setShow]);
  if (show) {
    return (
      <div className={classNames.root}>
        <div className={classNames.spinner}>
          <Spinner label="Зареждане, моля изчакайте…" size={SpinnerSize.large} />
        </div>
      </div>
    );
  } else {
    return null;
  }
};

export const WrapLoading = <T,>({loadable, render}: {loadable: ILoadable<T>; render: (loaded: T) => ReactNode}) => {
  switch (loadable.loadingState) {
    case ApiState.LOADING:
      return <Loading />;
    case ApiState.OK:
      return <>{render(loadable.value)}</>;
    case ApiState.ERROR:
    case ApiState.NA:
      return null;
    default:
      throw new UnreachableCaseError(loadable);
  }
};
