import {IconButton, IContextualMenuItem, Spinner, SpinnerSize, useWindow} from "@fluentui/react";
import {ICON_PROPS} from "app/utils/icons";
import {toggleFeature, useAdminStore} from "app/state/admin";
import {query} from "app/gql/client";
import {LoginGenerateTokenDocument} from "app/gql/graphqlSchema";
import {useCallback, useEffect, useState} from "react";
import QRCode from "react-qr-code";
import {noop} from "app/utils/types";
import {styled} from "styled-components";

const TokenWrapper = styled.div`
  padding: 0.5rem;
`;

const GenerateLoginToken = () => {
  const [loginToken, setLoginToken] = useState<string | null>(null);
  const window = useWindow();

  const generateLoginToken = useCallback(async () => {
    const result = await query(LoginGenerateTokenDocument, {
      returnUrl: window?.location.href.replace(window.location.origin, "") ?? "/"
    });
    setLoginToken(`${window?.location.origin}/i/teleport/${result.loginGenerateToken}`);
  }, [window?.location.href, window?.location.origin]);

  useEffect(() => {
    generateLoginToken().finally(noop);
  }, [generateLoginToken]);

  return (
    <TokenWrapper>
      {loginToken ? (
        <a href={loginToken} rel="noreferrer" target="_blank">
          <QRCode size={256} value={loginToken} />
        </a>
      ) : (
        <Spinner size={SpinnerSize.large} />
      )}
    </TokenWrapper>
  );
};

export const AdminMenu = () => {
  const features = useAdminStore((state) => state.features);

  const toggleFeatureMenu =
    (feature: keyof typeof features): IContextualMenuItem["onClick"] =>
    (ev) => {
      toggleFeature(feature);
      ev?.preventDefault();
    };

  const menuItems: IContextualMenuItem[] = [
    {
      key: "Видео статистика",
      text: "Видео статистика в плейъра",
      canCheck: true,
      checked: features.showVideoStats,
      onClick: toggleFeatureMenu("showVideoStats")
    },
    {
      key: "Превъртане напред",
      text: "Превъртане напред",
      canCheck: true,
      checked: features.allowSeeking,
      onClick: toggleFeatureMenu("allowSeeking")
    },
    {
      key: "Не спирай на сегментите на видеото",
      text: "Не спирай на сегментите на видеото",
      canCheck: true,
      checked: features.disableChapters,
      onClick: toggleFeatureMenu("disableChapters")
    },
    {
      key: "Покажи верните отговори",
      text: "Покажи верните отговори",
      canCheck: true,
      checked: features.highlightAnswers,
      onClick: toggleFeatureMenu("highlightAnswers")
    },
    {
      key: "Тестови известия",
      text: "Тестови известия",
      canCheck: true,
      checked: features.testSendEnabled,
      onClick: toggleFeatureMenu("testSendEnabled")
    },
    {
      key: "Допълнителни функции в контролните карти",
      text: "Допълнителни функции в контролните карти",
      canCheck: true,
      checked: features.controlCardExtras,
      onClick: toggleFeatureMenu("controlCardExtras")
    },
    {
      key: "Прехвърляне на друго устройство",
      text: "Прехвърляне на друго устройство",
      subMenuProps: {
        items: [
          {
            key: "Генерирай линк",
            onRender: () => <GenerateLoginToken />
          }
        ]
      }
    }
  ];

  return <IconButton iconProps={ICON_PROPS.gearFull} menuProps={{items: menuItems}} />;
};
